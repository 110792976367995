import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { isChrome, isSafari } from 'react-device-detect';
import './index.css';
import Header from '../../components/Header/Header';
import FilterHeader from '../../components/FilterHeader/FilterHeader';
import InfoModal from '../../components/InfoModal/InfoModal';
import markerCafeImg from '../../assets/images/logo-marker.png';
import markerHomeImg from '../../assets/images/logo-marker-home.png';
import homeIcon from '../../assets/images/3d-home.png';
import mapIcon from '../../assets/images/3d-map.png';
import { IoIosCloseCircleOutline } from 'react-icons/io';
import PocketBase from 'pocketbase';
import { Map, MapMarker, CustomOverlayMap } from 'react-kakao-maps-sdk';
import { getImageUrl } from '../../lib/optimizer';

const isKakaoTalkInAppBrowser = () => {
  const userAgent = window.navigator.userAgent.toLowerCase();
  return userAgent.includes('kakaotalk');
};

const pb = new PocketBase('https://saengcaday.pockethost.io');
// console.log(pb_dt);
// const { kakao } = window;

const Main = () => {
  const [cafes, setCafes] = useState([]);
  const [openCafeIds, setOpenCafeIds] = useState([]);
  const [currentLocation, setCurrentLocation] = useState(null);
  const [region, setRegion] = useState([]);
  const [expandedRegions, setExpandedRegions] = useState(false);
  const markerHomeSize = { width: 24, height: 40 };
  const markerCafeSize = { width: 32, height: 56 };

  const [showBrowserAlert, setShowBrowserAlert] = useState(false);
  const modalRef = useRef(null);

  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const artistQuery = queryParams.get('artist');
  const locationQuery = queryParams.get('location');
  const startDateQuery = queryParams.get('startdate');
  const endDateQuery = queryParams.get('enddate');

  const locationLevels = {
    홍대: 4,
    용산: 4,
    압구정: 4,
    JYP: 5,
    대구: 8,
    대전: 8,
    부산: 8,
  };

  const locations = {
    홍대: { latitude: 37.554931928869735, longitude: 126.92684298008352 },
    용산: { latitude: 37.52445581441742, longitude: 126.96418225765228 },
    압구정: { latitude: 37.5258284076036, longitude: 127.038921780666 },
    JYP: { latitude: 37.5241019, longitude: 127.1291142 },
    대구: { latitude: 35.8685177, longitude: 128.5977853 },
    대전: { latitude: 36.3291987, longitude: 127.4256126 },
    부산: { latitude: 35.151991, longitude: 129.0663397 },
  };

  useEffect(() => {
    // console.log('브라우저 정보:', browserName, browserVersion);
    if (!isChrome && !isSafari && !isKakaoTalkInAppBrowser()) {
      setShowBrowserAlert(true);
    }
  }, []);

  useEffect(() => {
    const getCurrentPosition = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(position => {
          const { latitude, longitude } = position.coords;
          setCurrentLocation({ lat: latitude, lng: longitude });
        });
      }
    };

    getCurrentPosition();

    const intervalId = setInterval(getCurrentPosition, 20000);

    return () => clearInterval(intervalId);
  }, []);

  const moveMapToCurrentLocation = () => {
    if (currentLocation) {
      setRegion({
        latitude: currentLocation.lat,
        longitude: currentLocation.lng,
      });
    }
  };

  const toggleRegions = () => {
    setExpandedRegions(!expandedRegions);
  };

  const moveToRegion = regionName => {
    setRegion(locations[regionName]);
    setExpandedRegions(false);
  };

  useEffect(() => {
    if (locationQuery) {
      pb.collection('regions')
        .getOne(locationQuery.split(',')[0])
        .then(res => {
          setRegion(res);
        })
        .catch(error => {
          console.error(error);
        });
    }
  }, [locationQuery]);

  useEffect(() => {
    const today = new Date().toISOString().split('T')[0];
    let filter_query = [`end_date > "${today}"`];

    if (endDateQuery) {
      const endDate = new Date(endDateQuery);
      endDate.setDate(endDate.getDate() + 1);
      filter_query.push(
        `start_date <= "${endDate.toISOString().split('T')[0]}"`,
      );
    }
    if (startDateQuery) {
      filter_query.push(`end_date >= "${startDateQuery}"`);
    }
    if (artistQuery) {
      const artist_list = artistQuery.split(',');
      const artist_query = artist_list.map(
        artist => `celebrities.id ?= "${artist}"`,
      );
      filter_query.push(`(${artist_query.join(' || ')})`);
    }
    pb.collection('events')
      .getFullList({
        filter: filter_query.join(' && '),
        sort: '-start_date',
        expand: 'cafe',
      })
      .then(events => {
        const cafeMarkers = events.map(event => ({
          position: {
            lat: event.expand.cafe.latitude,
            lng: event.expand.cafe.longitude,
          },
          name: event.expand.cafe.name,
          imageSrc: getImageUrl(event, event.poster_main, { w: 200 }),
          id: event.id,
        }));
        setCafes(cafeMarkers);
        setOpenCafeIds(cafeMarkers.map(cafe => cafe.id)); // Open 유지
      });
    // eslint-disable-next-line
  }, []);

  const toggleCafeInfo = cafeId => {
    if (openCafeIds.includes(cafeId)) {
      setOpenCafeIds(openCafeIds.filter(id => id !== cafeId));
    } else {
      setOpenCafeIds([...openCafeIds, cafeId]);
    }
  };

  // console.log('생카 정보', cafes);

  return (
    <div>
      <Header />
      <div className="absolute w-full px-4 sm:px-12 tb:px-24 lg:px-36 xl:px-64 2xs:top-14 top-20 lg:top-24 z-30">
        <FilterHeader />
      </div>

      {showBrowserAlert && (
        <div ref={modalRef}>
          <InfoModal
            shown={showBrowserAlert}
            onHide={() => setShowBrowserAlert(false)}
          />
        </div>
      )}

      <div className="absolute bottom-12 right-6 tb:bottom-20 tb:right-10 lg:bottom-24 lg:right-14 z-30">
        <section className="flex flex-row 2xs:gap-3 gap-4 tb:gap-6 lg:gap-8 shadow-none border-none">
          <div
            onClick={moveMapToCurrentLocation}
            className="flex flex-col items-center justify-center"
          >
            <div className="flex items-center justify-center 2xs:w-12 2xs:h-12 xs:w-14 xs:h-14 w-16 h-16 tb:w-20 tb:h-20 lg:w-24 lg:h-24 rounded-full drop-shadow-md bg-white">
              <img
                src={homeIcon}
                className="2xs:w-9 2xs:h-9 xs:w-10 xs:h-10 w-12 h-12 tb:w-16 tb:h-16 lg:w-20 lg:h-20"
                alt="현재 위치로 이동"
              />
            </div>
          </div>
          <div
            onClick={toggleRegions}
            className="flex flex-col items-center justify-center"
          >
            <div className="flex items-center justify-center 2xs:w-12 2xs:h-12 xs:w-14 xs:h-14 w-16 h-16 tb:w-20 tb:h-20 lg:w-24 lg:h-24  rounded-full drop-shadow-md bg-white">
              <img
                src={mapIcon}
                className="2xs:w-9 2xs:h-9 xs:w-10 xs:h-10 w-12 h-12 tb:w-16 tb:h-16 lg:w-20 lg:h-20"
                alt="지역으로 이동"
              />
            </div>
            <div className="absolute 2xs:bottom-16 bottom-20 tb:bottom-24 lg:bottom-32 right-0 z-40">
              {expandedRegions && (
                <ul className="flex flex-col 2xs:gap-2 gap-3 tb:gap-4 justify-center items-center h-full">
                  {Object.keys(locations).map(region => (
                    <li
                      key={region}
                      className="flex items-center justify-center 2xs:w-12 2xs:h-6 2xs:text-xs xs:w-14 xs:h-6 xs:text-sm w-16 h-8 tb:w-20 tb:h-10 lg:w-24 lg:h-12 text-base tb:text-lg lg:text-xl font-medium rounded-full drop-shadow-md bg-white"
                      onClick={() => moveToRegion(region)}
                    >
                      {region}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
        </section>
      </div>
      <div className="flex w-full justify-center">
        <Map
          id="map"
          center={{
            lat: (region && region.latitude) || 37.554931928869735,
            lng: (region && region.longitude) || 126.92684298008352,
          }}
          level={(region && locationLevels[region.id]) || 4}
          className="w-full min-h-screen"
        >
          {cafes.map(cafe => (
            <a href={`/info/${cafe.id}`} key={cafe.id}>
              <MapMarker
                key={cafe.id}
                onClick={() => toggleCafeInfo(cafe.id)}
                position={cafe.position}
                image={{
                  src: markerCafeImg,
                  size: markerCafeSize,
                  options: {
                    offset: {
                      x: 16,
                      y: 64,
                    },
                  },
                }}
              />
              {openCafeIds.includes(cafe.id) && (
                <CustomOverlayMap
                  key={`overlay_${cafe.id}`}
                  position={cafe.position}
                  xAnchor={0.5}
                  yAnchor={1}
                >
                  <div className="wrap">
                    <div className="info w-full">
                      <div className="title">{cafe.name}</div>
                      <button
                        className="close"
                        onClick={() => toggleCafeInfo(cafe.id)}
                      >
                        <IoIosCloseCircleOutline />
                      </button>
                      <a
                        href={`/info/${cafe.id}`}
                        className="flex justify-center"
                      >
                        <div className="img">
                          <img src={cafe.imageSrc} alt="Cafe Poster" />
                        </div>
                      </a>
                    </div>
                  </div>
                </CustomOverlayMap>
              )}
            </a>
          ))}
          {currentLocation && (
            <>
              <MapMarker
                position={currentLocation}
                image={{
                  src: markerHomeImg,
                  size: markerHomeSize,
                  options: {
                    offset: {
                      x: 16,
                      y: 64,
                    },
                  },
                }}
              />
              <CustomOverlayMap
                position={currentLocation}
                xAnchor={0.3}
                yAnchor={1}
              >
                <div className="wrap-home">
                  <div className="info">
                    <div className="home-title">현 위치</div>
                  </div>
                </div>
              </CustomOverlayMap>
            </>
          )}
        </Map>
      </div>
    </div>
  );
};

export default Main;
